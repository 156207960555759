import * as React from "react";

import "../styles/style.scss";

import data from "../data/data.json";

// markup
class IndexPage extends React.Component {
  state = {
    card: 0,
  };

  nextCard = () => {
    if (this.state.card < data.cards.length - 1) {
      this.setState({
        card: this.state.card + 1,
      });
    }
  };

  prevCard = () => {
    if (this.state.card >= 1) {
      this.setState({
        card: this.state.card - 1,
      });
    }
  };
  render() {
    return (
      <main>
        <div className="container">
          <title>The Letter M</title>
          <h1>
            M is for <em>{data.cards[this.state.card].word}</em>
          </h1>
          <p>{data.cards[this.state.card].text}</p>
          <p>
            <em>ex: {data.cards[this.state.card].example}</em>
          </p>
          <div className="button-group">
            <button
              className={`button ${this.state.card <= 0 && "button--disabled"}`}
              onClick={this.prevCard}
            >
              Back
            </button>
            <button
              className={`button button--primary ${
                this.state.card >= data.cards.length - 1 && "button--disabled"
              }`}
              onClick={this.nextCard}
            >
              Next
            </button>
          </div>
        </div>
      </main>
    );
  }
}

export default IndexPage;
